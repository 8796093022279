export const DiamondData = {
  stoneshape: [
    { icon: "/diamondSvg/Round.png", text: "Round" },
    { icon: "/diamondSvg/Oval.png", text: "Oval" },
    { icon: "/diamondSvg/Antiqe Oval.png", text: "Antique Oval" },
    { icon: "/diamondSvg/Emerald.png", text: "Emerald" },
    { icon: "/diamondSvg/Radiant.png", text: "Radiant" },
    { icon: "/diamondSvg/Cushion.png", text: "Cushion" },
    { icon: "/diamondSvg/Antique Cushion.png", text: "Antique Cushion" },
    { icon: "/diamondSvg/Pear.png", text: "Pear" },
    { icon: "/diamondSvg/Heart.png", text: "Heart" },
    { icon: "/diamondSvg/Princess.png", text: "Princess" },
    { icon: "/diamondSvg/Ascher.png", text: "Asscher" },
    { icon: "/diamondSvg/Marquise.png", text: "Antique Marquise" },
    { icon: "/diamondSvg/Marquise.png", text: "Marquise" },
    { icon: "/diamondSvg/SQ. Emerald.png", text: "SQ. Emerald" },
    { icon: "/diamondSvg/SQ. Radiant.png", text: "SQ. Radiant" },
    { icon: "/diamondSvg/European Cut.png", text: "Old European Cut" },
    { icon: "/diamondSvg/Hexagonal.png", text: "Hexagonal" },
    { icon: "/diamondSvg/Octagonal.png", text: "Octagonal" },
    { icon: "/diamondSvg/Rose.png", text: "Rose Cut" },
    { icon: "/diamondSvg/Bugget.png", text: "Baguette" },
    { icon: "/diamondSvg/Trapped Budget.png", text: "Tapered Baguette" },
    { icon: "/diamondSvg/Tapered Bullet.png", text: "Tapered Bullet" },
    { icon: "/diamondSvg/Trapezoid.png", text: "Trapezoid" },
    { icon: "/diamondSvg/Bullet Cut.png", text: "Bullet Cut" },
    { icon: "/diamondSvg/Half Moon.png", text: "Half Moon" },
    // { icon: "/diamondSvg/Old Mine Cut.png", text: "Old Mine Cut" },
    { icon: "/diamondSvg/Pentagonal.png", text: "Pentagonal" },
    { icon: "/diamondSvg/Circular Brilliant.png", text: "Circular Brilliant" },
    { icon: "/diamondSvg/Lozenge.png", text: "Lozenge" },
    { icon: "/diamondSvg/Shield.png", text: "Shield" },
    { icon: "/diamondSvg/Star.png", text: "Star" },
    { icon: "/diamondSvg/Trilliant Cut.png", text: "Trilliant Cut" },
    { icon: "/diamondSvg/Kite.png", text: "Kite" },
    { icon: "/diamondSvg/Briolette.png", text: "Briolette" },
    { icon: "/diamondSvg/Alphabet.png", text: "Alphabet" },
    { icon: "/diamondSvg/Animal.png", text: "Animal" },
    { icon: "/diamondSvg/Butterfly.png", text: "Butterfly" },
    { icon: "/diamondSvg/Antique Asscher.png", text: "Antique Asscher" },
    { icon: "/diamondSvg/Criss Cut.png", text: "Criss Cut" },
    { icon: "/diamondSvg/Modified Cut.png", text: "Modified Cut" },
    { icon: "/diamondSvg/Modified Step Cut.png", text: "Modified Step Cut" },
    { icon: "/diamondSvg/Old Mine.png", text: "Old Mine" },
    { icon: "/diamondSvg/Triangle.png", text: "Triangle" },
    { icon: "/diamondSvg/Unique.png", text: "Unique" },
    { icon: "/diamondSvg/Moval.png", text: "Moval" },
    { icon: "/diamondSvg/mix.png", text: "Other" },
  ],
  diamondCts: [
    "0.01-0.29",
    "0.30-0.39",
    "0.40-0.49",
    "0.50-0.69",
    "0.70-0.89",
    "0.90-0.99",
    "1.00-1.49",
    "1.50-1.99",
    "2.00-2.99",
    "3.00-3.99",
    "4.00-4.99",
    "5.00+",
  ],

  Availability: ["Stock ", "Memo"],

  Color: [
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O-P",
    "Q-R",
    "S-T",
    "U-V",
    "W-X",
    "Y-Z",
    "MIX",
    "FV.P",
    "FDK.BR",
  ],

  F_Color: ["Blue", "Green", "Orange", "Pink", "Yellow"],
  Clarity: [
    "FL",
    "IF",
    "VVS1",
    "VVS2",
    "VS1",
    "VS2",
    "SI1",
    "SI2",
    "SI3",
    "I1",
    "I2",
    "I3",
    "MIX",
  ],

  Cut_Grade: ["Excellent", "Very Good", "Good", "Fair", "Poor", "None"],
  Polish: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
  Symmetry: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
  report: ["GIA", "IGI", "NON CERT"],
  location: ["New York", "Los Angeles"],
};
